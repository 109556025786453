@use '@styles/_partials' as *;

:global(html[dir='rtl']) .swiper {
  & :global(.swiper-button-prev) {
    transform: rotate(0deg);
    left: initial;
    right: 0;
  }

  & :global(.swiper-button-next) {
    transform: rotate(180deg);
    left: 0;
    right: initial;
  }

  & .swiperItemInfo {
    margin-left: unset;
    margin-right: calc(50% - 110px);
  }
}
