@use '../../../styles/_partials' as *;
@use './RelatedProducts.rtl.scss';
@use '@styles/components/Switch.scss' as *;
@use 'sass:math';

@mixin fadeIn {
  opacity: 1;
  max-height: 100%;
  overflow: visible;
  transform: translateX(0);
}

@mixin fadeOutLeft {
  opacity: 0;
  @include respond-to($lg) {
    @media (prefers-reduced-motion: no-preference) {
      transform: translateX(-20px);
    }
  }
}

@mixin fadeOutRight {
  opacity: 0;
  @include respond-to($lg) {
    @media (prefers-reduced-motion: no-preference) {
      transform: translateX(20px);
    }
  }
}

.relatedProducts {
  color: $white;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  text-align: center;
  padding-bottom: pxToRem(36px);
  @include respond-to($lg) {
    padding-bottom: pxToRem(16px);
  }
}

.subtitle {
  text-align: center;
  padding-bottom: pxToRem(60px);
  @include font-size(16px);
  @include line-height(20);
  @include respond-to($lg) {
    @include font-size(20px);
    @include line-height(27);
  }
}

.mobileSwitchRow {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: pxToRem(37px);

  button {
    margin: 0 pxToRem(22px);
  }

  p {
    font-weight: bold;
    @include font-size(16px);
    @include line-height(22);
  }
}

.desktopSwitchRow {
  display: none;
  @include respond-to($lg) {
    display: flex;
  }
  justify-content: center;
  align-items: center;
  padding-bottom: pxToRem(85px);

  button {
    margin: 0 pxToRem(24px);
  }

  p {
    font-weight: bold;
    @include font-size(18px);
    @include line-height(25);
    transition: color 0.2s;
  }
}

.switchButton {
  background: transparent;
  border: none;
  font-size: inherit;
  color: inherit;
  font-family: inherit;
  cursor: pointer;

  &:hover {
    color: $primary;
  }
}

.greenText {
  color: $primary;
}

.switch {
  position: relative;
  cursor: pointer;
  background-color: $darkGray;
  @include switchStyles(40px, 75px);

  @include respond-to($lg) {
    @include switchStyles(44px, 85px);
  }
}

.pistachioBags {
  display: flex;
  position: relative;
  width: 228px;
  flex-wrap: wrap;

  @include respond-to($md) {
    width: 342px;
  }

  @include respond-to($lg) {
    width: unset;
    justify-content: center;
    padding-bottom: pxToRem(30px);
  }

  & li {
    display: inherit;
    @include respond-to($lg) {
      margin-right: 25px;

      &:last-child {
        margin-right: 0px;
      }
    }
  }
}

.pistachioBagContainer {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding-bottom: pxToRem(20px);
  @include respond-to($lg) {
    padding-bottom: 0;
  }

  .productLink {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    color: transparent;
    z-index: 3;
    cursor: pointer;
  }

  .bagImagesWrapper {
    order: 1;
  }

  .pistachioBagNameContainer {
    display: inherit;
    z-index: 1;
    order: 2;
    outline: none;
  }

  .crossfadeWrapper {
    order: 1;
  }

  @include respond-to($lg) {
    width: 175px;
  }
}

.bagImagesWrapper {
  position: relative;
  height: 150px;
  width: 90px;
  margin-bottom: pxToRem(16px);

  @include respond-to($md) {
    height: 225px;
    width: 135px;
  }

  @include respond-to($lg) {
    height: 230px;
    width: 138px;
  }

  transition: transform 0.2s, opacity 0.3s;
  &:active {
    transition: transform 0.1s, opacity 0.2s;
    opacity: 0.7;
    transform: scale(0.95);
    @include respond-to($lg) {
      opacity: 1;
      transform: scale(1);
    }
  }

  // circle gradient
  &::before {
    width: 266px;
    height: 266px;
    position: absolute;
    @include respond-to($lg) {
      content: '';
    }
    opacity: 0;
    transition: opacity 0.4s;
    margin: auto;
    top: -25px;
    left: -50%;
    background-image: radial-gradient(
      circle 133px at 50% 50%,
      rgb(255, 255, 255) 0px,
      rgba(86, 86, 86, 0.25) 72%,
      rgba($offBlackLt, 0) 100%
    );
    pointer-events: none;

    .pistachioBagContainer:hover &,
    .pistachioBagContainer.active & {
      opacity: 0.4;
      transition: opacity 0.2s;
    }
  }
}

.bagImageWrapper {
  position: absolute;
  height: 100%;
  width: 100%;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  transition: opacity 0.3s;
  @include fadeIn();

  @include respond-to($lg) {
    transition: opacity 0.3s ease-in-out, transform 0.6s ease;
    // we stagger the flips on desktop with a transition delay,
    // but that's too intense for mobile (or for prefers-reduced-motion)
    @media (prefers-reduced-motion: no-preference) {
      transition-delay: var(--flip-delay);
    }
  }

  .pistachioBagContainer.reversed &:not(.backside) {
    opacity: 0;
    @include respond-to($lg) {
      @include fadeOutLeft();
    }
  }

  &.backside {
    opacity: 0;
    @include respond-to($lg) {
      @include fadeOutRight();
    }

    .pistachioBagContainer.reversed & {
      @include fadeIn();
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: bottom;
  }
}

.bestSellerIcon {
  font-family: $primaryFontBold;
  position: absolute;
  background-color: $primary;
  text-transform: uppercase;
  $size: 40px;
  height: $size;
  width: $size;
  border-radius: math.div($size, 2);
  top: -14px;
  right: -3px;

  .backside & {
    top: 18px;
  }

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: $black;
  font-weight: bold;

  @include font-size(9px);
  @include line-height(11);

  @include respond-to($lg) {
    $size: 60px;
    height: $size;
    width: $size;
    border-radius: math.div($size, 2);
    top: -18px;
    right: -5px;

    @include font-size(12px);
    @include line-height(14);

    .backside & {
      top: 25px;
    }
  }
}

.crossfadeWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.crossfadeItemWrapper {
  width: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  transition: opacity 0.3s ease-in-out, transform 0.6s ease;

  @include fadeIn();

  &:not(.backside) {
    .reversed & {
      @include fadeOutLeft();
      overflow: hidden;
      max-height: 0;
    }
  }

  &.backside {
    @include fadeOutRight();
    overflow: hidden;
    max-height: 0;

    .reversed & {
      @include fadeIn();
    }
  }

  @include respond-to($lg) {
    @media (prefers-reduced-motion: no-preference) {
      transition-delay: var(--flip-delay);
    }
  }
}

.pistachioBagName {
  color: $white;
  position: relative;
  text-align: center;
  font-family: $primaryFontBold;
  @include font-size(16px);
  @include line-height(22);
  padding-bottom: pxToRem(6px);
  z-index: 2;

  .pistachioBagContainer:focus &,
  .pistachioBagContainer:hover &,
  .pistachioBagContainer:active &,
  .pistachioBagContainer.active & {
    color: $primary;
  }

  &::after {
    // underline that appears under the text
    content: '';
    height: 1px;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: $primary;
    opacity: 0;
    transform: translateY(-6px);
    transition: transform 0.2s, opacity 0.2s;

    .pistachioBagContainer:focus &,
    .pistachioBagContainer:hover &,
    .pistachioBagContainer.active & {
      // transition: transform 0.2s, opacity 0.2s;
      opacity: 1;
      transform: translateY(0);
    }
  }
}

.pistachioBagDescription {
  display: none;
  overflow: hidden;
  @include respond-to($lg) {
    display: block;
  }

  padding-top: 10px;
  text-align: center;
  @include font-size(14px);
  @include line-height(18);

  position: relative;
  overflow: hidden;
  z-index: 0;

  @media (prefers-reduced-motion: no-preference) {
    transform: translateY(-7px);
  }

  transition: transform 0.4s;
  .pistachioBagContainer:hover &,
  .pistachioBagContainer:focus &,
  .pistachioBagContainer.active & {
    transform: translateY(0);
    // a tiny delay on the reveal animation, so it subtly staggers after the
    // animations above it
    transition: transform 0.3s 0.07s;
  }

  &::after {
    // this gradient covers the text when the container isn't focused or hovered
    // on focus/hover, the animation slowly reveals the text
    content: '';
    position: absolute;
    top: -100%;
    left: 0;
    width: 100%;
    height: 200%;
    background: linear-gradient(transparent, $offBlack 50%, $offBlack 100%);
    transition: inherit;

    .pistachioBagContainer:hover &,
    .pistachioBagContainer:focus &,
    .pistachioBagContainer.active & {
      transform: translateY(100%);
    }
  }
}

@media (prefers-reduced-motion: no-preference) {
  .title,
  .subtitle,
  .pistachioBagContainer,
  .mobileSwitchRow,
  .desktopSwitchRow {
    opacity: 0;
  }

  .isInViewport {
    .title {
      animation: revealItem forwards $animationDuration;
    }
    .subtitle,
    .mobileSwitchRow,
    .desktopSwitchRow {
      animation: revealItem forwards $animationDuration
        calc($animationDelay - $animationDelayAddend);
    }
    .pistachioBagContainer {
      animation: revealItem forwards $animationDuration var(--transition-delay);
    }
  }
}
