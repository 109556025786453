@import './reset.css';
@import 'fonts.scss';
@import './globals.scss';
@import './variables.scss';

// Bootstrap grid
@import './lib/bootstrap/variables';
@import './lib/bootstrap/mixins/breakpoints';
@import './lib/bootstrap/mixins/grid';
@import './lib/bootstrap/mixins/container';
@import './lib/bootstrap/functions';
@import 'lib/bootstrap/bootstrap-grid.scss';

//SwiperJS
@import '../node_modules/swiper/swiper.scss';
@import '../node_modules/swiper/modules/navigation/navigation.scss';
@import '../node_modules/swiper/modules/pagination/pagination.scss';
@import '../node_modules/swiper/modules/a11y/a11y.scss';

// WWC Components
@import './components/PrivacyPolicy.scss';

//nprogress
@import './components/nprogress.scss';
