@import '@styles/_partials';

.privacy__policy__container {
  max-width: 760px;
  margin: 0 auto;

  @include font-size(20px);

  @include body-font();

  @include respond-to($lg) {
    padding: 0 30px;
  }
  h1 {
    display: none;
  }
  ul {
    list-style: disc;
    padding-left: 20px;
  }
  & h2 {
    @include body-font();
  }
  b,
  h2 {
    font-weight: bold;
  }
  i {
    font-style: italic;
  }
  a {
    cursor: pointer;
  }
  p,
  h2,
  h3,
  ul {
    padding-bottom: 28px;
  }
  table {
    border-collapse: collapse;
    width: 100%;
    border: 1px solid $white;
    margin-bottom: 1.5rem;
    th,
    td {
      border: 1px solid $white;
      padding: 1rem;
      text-align: left;
      vertical-align: top;
    }
  }
}
