@use '@styles/_partials' as *;
@use 'sass:math';

$height: 35px;
$heightLg: 44px;

.buttonElement {
  grid-area: button;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $primary;
  border: none;
  color: $black;
  @include font-size(14px);
  font-family: $primaryFontBold;
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  max-width: calc(100% - 40px);
  padding: 0 pxToRem(25px);
  min-height: $height;
  cursor: pointer;
  transition: opacity 0.3s;

  @include respond-to($lg) {
    @include font-size(16px);
    min-height: $heightLg;
  }

  &:hover {
    opacity: 0.8 !important; //Important needed to override reveal animation styles
  }

  &:active {
    opacity: 0.6 !important; //Important needed to override reveal animation styles
    transition: transform 0.1s, opacity 0.2s;
  }

  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:active {
    outline: none;
  }

  &:active:not(:disabled) {
    transform: perspective(100px) translateZ(-5px);

    @include respond-to($lg) {
      transform: perspective(100px) translateZ(-8px);
    }
  }

  &:global(.round) {
    padding: 0 pxToRem(5px);
    position: relative;
    .semiCircleLeft {
      z-index: 0;
      position: absolute;
      height: 100%;
      width: 40px;
      left: -20px;
      border-radius: 46%;
      background: $primary;

      @include respond-to($lg) {
        border-radius: 50%;
      }
    }

    .semiCircleRight {
      z-index: 0;
      position: absolute;
      height: 100%;
      width: 40px;
      right: -20px;
      border-radius: 46%;
      background: $primary;

      @include respond-to($lg) {
        border-radius: 50%;
      }
    }

    .buttonText {
      z-index: 1;
    }
  }

  span {
    padding: pxToRem(4px) 0;
    overflow: hidden;
    @include line-height(17px);
  }
}

button.buttonElement {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

a.buttonElement {
  align-items: center;
  justify-content: center;
  width: fit-content;
}
