@use '@styles/_partials' as *;

.bgGradient {
  background-image: linear-gradient(180deg, $offBlackXLt 0%, $offBlack 100%);
}

.innerContainer {
  text-align: center;
  max-width: $containerMaxWidth;
  margin: 0 auto;
  padding-top: pxToRem(20px);
  padding-bottom: pxToRem(20px);
  @include respond-to($md) {
    padding: pxToRem(30px) pxToRem(40px);
  }
  @include respond-to($lg) {
    padding: pxToRem(30px) pxToRem(60px);
  }

  .eyebrow {
    color: $primary;
    font-family: $primaryFontBold;
    font-weight: bold;
    margin-bottom: pxToRem(20px);
    display: block;
    text-transform: none;
    letter-spacing: normal;

    @include font-size(20px);
    @include line-height(27);
    @include respond-to($md) {
      @include font-size(23px);
      @include line-height(28);
    }
    @include respond-to($lg) {
      @include font-size(25px);
      @include line-height(30);
    }
  }

  .title {
    font-family: $primaryFontHeavy;
    text-transform: uppercase;
    margin-bottom: pxToRem(30px);
    letter-spacing: 7.2px;
    @include font-size(26px);
    @include line-height(32);
    @include respond-to($sm) {
      @include font-size(36px);
      @include line-height(40);
    }
    @include respond-to($md) {
      margin-bottom: pxToRem(25px);
      letter-spacing: 8px;
      @include font-size(42px);
      @include line-height(58);
    }
    @include respond-to($lg) {
      margin-bottom: pxToRem(30px);
      letter-spacing: 9.8px;
      @include font-size(49px);
      @include line-height(67);
    }
  }

  .description {
    @include font-size(18px);
    @include line-height(22);
    margin-bottom: pxToRem(30px);
    @include respond-to($md) {
      @include font-size(22px);
      @include line-height(28);
    }
    @include respond-to($lg) {
      @include font-size(24px);
      @include line-height(34);
    }
  }
  .button {
    margin: 0 auto;
  }
}
