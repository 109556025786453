@use '@styles/_partials' as *;
@use '@styles/components/Switch.scss' as *;
@use 'sass:math';

:global(html[dir='rtl']) .switch {
  @include switchStyles(40px, 75px, true);

  @include respond-to($lg) {
    @include switchStyles(44px, 85px, true);
  }
}
