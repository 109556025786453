@use '../../../styles/_partials' as *;
@use './ProductCarousel.rtl.scss';

.container {
  width: 100%;
  margin-top: pxToRem(40px);

  .titles {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: auto;

    @include respond-to($lg) {
      width: 740px;
    }

    & span,
    & h2,
    & p {
      text-align: center;
    }

    & p {
      @include font-size(24px);
      @include line-height(34);
    }

    & span {
      @include h3();
      color: $primary;
    }

    & h2 {
      @include h1();
      text-transform: uppercase;
    }
  }
}

.swiper {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: pxToRem(30px);
  width: 90%;

  @include respond-to($md) {
    width: 100%;
  }

  & :global(.swiper-wrapper) {
    align-items: center;
  }

  & :global(.swiper-pagination) {
    position: relative;
    order: 1;
    bottom: 0;
    margin-top: pxToRem(30px);

    --swiper-pagination-bullet-horizontal-gap: #{pxToRem(9px)};
  }

  & :global(.swiper-button-prev),
  & :global(.swiper-button-next) {
    height: 32px;
    margin-top: pxToRem(-16px);
    top: calc(50% - 43px);

    &:after {
      font-size: 32px;
      color: white;
    }

    @include respond-to($sm) {
      height: 64px;
      margin-top: pxToRem(-32px);
      &:after {
        font-size: 64px;
        color: white;
      }
    }
  }

  & :global(.swiper-button-prev),
  & :global(.swiper-button-next) {
    &:after {
      background: url('../../../public/images/home/arrow_right.svg');
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      content: '';
      height: 100%;
      width: 100%;
      opacity: 1;
      transition: opacity 0.3s ease;
    }
    &:focus:after,
    &:hover:after {
      opacity: 0.5;
    }
  }
  & :global(.swiper-button-prev) {
    transform: rotate(180deg);
    left: 0;
    @include respond-to-max-width($maxMd) {
      display: none;
    }
  }

  & :global(.swiper-button-next) {
    right: 0;
    @include respond-to-max-width($maxMd) {
      display: none;
    }
  }

  & :global(#ada-content) {
    position: absolute;
    top: 10px;
    bottom: 35px;
    color: transparent;
    right: calc(50% - 110px);
    width: 220px;

    & a {
      @include fullAbsoute();

      &:focus {
        outline: auto;
      }
    }
  }

  & .swiperItem {
    flex-direction: column;
    align-items: center;

    & .imageWrapper {
      position: relative;
      overflow: visible;
      height: 340px;

      @include respond-to($lg) {
        height: 490px;
      }
    }

    &:global(.swiper-slide-active) {
      & .imageWrapper {
        &::before {
          z-index: -1;
          pointer-events: none;
          content: '';
          position: absolute;
          height: 100%;
          width: 200%;
          margin-left: -50%;
          left: 0;
          -webkit-filter: blur(10px);
          filter: blur(20px);
          background-image: radial-gradient(
            closest-side at 50% 50%,
            rgba($white, 0.55) 0%,
            rgba(#565656, 0.25) 75%,
            rgba(#40311f, 0) 100%
          );
        }
      }
    }

    & img {
      width: 100%;
      transform: scale(0.75);
      transition: transform 0.3s;
      object-fit: contain;
      object-position: center;
      height: 290px;

      @include respond-to($lg) {
        height: 425px;
        transform: scale(0.55);
      }
    }

    & .swiperItemInfo {
      display: flex;
      opacity: 0;
      flex-direction: column;
      align-items: center;
      transition: opacity 0.5s;
      width: 220px;
      margin-left: calc(50% - 110px);

      strong {
        text-align: center;
      }
    }

    &:global(.swiper-slide-active) {
      & .imageWrapper {
        &::before {
          opacity: 1;
        }
      }
      & img {
        transform: scale(1);
      }
      & .swiperItemInfo {
        opacity: 1;
      }
    }

    @include respond-to($lg) {
      &:global(.swiper-slide-prev),
      &:global(.swiper-slide-next) {
        & img {
          transform: scale(0.75);
        }
      }
    }
  }

  :global(.swiper-pagination-bullet) {
    background: $ltGray;
    width: 10px;
    height: 10px;

    @include respond-to($sm) {
      width: 20px;
      height: 20px;
    }

    &:global(.swiper-pagination-bullet-active) {
      background: white;
    }
  }
}

@media (prefers-reduced-motion: no-preference) {
  .container .titles,
  .swiper {
    opacity: 0;
  }

  .inViewport {
    .titles {
      animation: revealItem forwards $animationDuration;
    }
    .swiper {
      animation: revealItem forwards $animationDuration $animationDelay;
    }
  }
}

.sm {
  display: block;

  @include respond-to($lg) {
    display: none;
  }
}

.lg {
  display: none;

  @include respond-to($lg) {
    display: block;
  }
}
