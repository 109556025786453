@use '@styles/_partials' as *;

:global(html[dir='rtl']) .productContainer {
  .iconsContainer {
    svg {
      margin-right: initial;
      margin-left: pxToRem(20px);
      &:last-of-type {
        margin-left: 0;
      }
    }
  }
}
