@use '../../../styles/_partials' as *;

.section {
  margin-top: pxToRem(60px);

  &.first {
    margin-top: 0px;
  }

  &.last {
    margin-bottom: 0px;
  }

  &.noMargin {
    margin-top: 0px;
    margin-bottom: 0px;
  }
}
