#nprogress {
  /* Make clicks pass-through */
  pointer-events: none;

  /* Progress Bar */
  .bar {
    background: var(--primary-theme-color) !important;
    position: fixed;
    z-index: 1031;
    top: 0;
    left: 0;

    width: 100%;
    height: 5px;
  }

  /* Fancy blur effect */
  .peg {
    display: block;
    position: absolute;
    right: 0px;
    width: 100px;
    height: 100%;
    box-shadow: 0 0 10px var(--primary-theme-color),
      0 0 5px var(--primary-theme-color);
    opacity: 1;

    transform: rotate(3deg) translate(0px, -4px);
  }
}
