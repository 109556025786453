@use '@styles/_partials' as *;
@use './ProductMain.rtl.scss';
@use 'sass:math';

.productContainer {
  position: relative;

  @include respond-to($lg) {
    padding: 0 pxToRem(80px);
  }

  .buttonContainer {
    width: 100%;

    .button {
      background: $primary;
      background: var(--primary-theme-color);
      margin: pxToRem(15px) auto pxToRem(25px);
      order: 0;
      @include respond-to($lg) {
        margin: pxToRem(15px) 0 pxToRem(25px) pxToRem(20px);
      }

      span {
        background: var(--primary-theme-color);
      }
    }
  }
}

.productTitle {
  width: 100%;
  letter-spacing: 0;
  color: $white;
  display: block;
  @include font-size(28px);
  @include line-height(38);
  font-weight: 700;
  margin-bottom: pxToRem(20px);
  position: relative;
  z-index: 1;
  font-family: $primaryFontBold;
  span {
    font-family: $primaryFontRegular;
    display: block;
  }
}

.productCategoryContainer {
  color: $primary;
  color: var(--primary-theme-color);
  @include font-size(16px);
  @include line-height(22);
  position: relative;
  z-index: 10;

  .productCategory {
    filter: brightness(125%);
    transition: filter 0.15s ease;
    &:hover {
      filter: brightness(100%);
    }
  }
}

.productDetailsContainer {
  z-index: 50; // higher index vs background gradient on image
  display: flex;
  flex-wrap: wrap;

  h1 {
    font-family: $primaryFontBold;
    letter-spacing: 0;

    span {
      font-family: $primaryFontRegular;
      display: block;
    }
  }

  // set initial state for animations
  > * {
    opacity: 0;
  }
}

.productHeadingBlock {
  opacity: 0;
  width: 100%;
  flex-direction: column-reverse;
}

.productDescription {
  margin: pxToRem(20px) 0;
  order: 1;

  @include respond-to($lg) {
    order: 0;
    max-width: 350px;
  }

  i {
    color: $gray;
  }

  :global([data-disclosure-id]) {
    cursor: pointer;

    &:hover,
    &:focus {
      color: var(--primary-theme-color);
    }
  }
}

.disclosures {
  order: 1;
  color: transparentize($white, 0.2);

  @include respond-to($lg) {
    max-width: 350px;
    order: 0;
  }

  .disclosure {
    order: 1;
    color: transparentize($white, 0.2);
    display: flex;
    margin-bottom: pxToRem(10px);

    @include respond-to($lg) {
      max-width: 350px;
      order: 0;
    }

    &.focused,
    &:focus {
      color: var(--primary-theme-color);
    }

    p {
      @include font-size(16px);
    }
  }
}

.nutritionalFactsLink {
  width: 100%;
  border-bottom: 1px solid;
  border-color: transparentize($white, 0.6);
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: pxToRem(25px) 0 pxToRem(20px);
  padding-bottom: pxToRem(5px);
  cursor: pointer;
  transition: color 0.3s ease, border-color 0.3s ease;
  order: 1;

  @include respond-to($lg) {
    max-width: 350px;
    order: 0;
  }

  svg {
    path,
    line {
      transition: color 0.3s ease, border-color 0.3s ease;
    }
  }

  &:hover,
  &:focus {
    color: var(--primary-theme-color);

    svg {
      path,
      line {
        stroke: var(--primary-theme-color);
      }
    }
  }
}

.iconsContainer {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: pxToRem(30px) 0;
  order: 1;
  @include respond-to($lg) {
    order: 0;
  }

  svg {
    margin-right: pxToRem(20px);
    display: block;
    &:last-of-type {
      margin-right: 0;
    }
  }
}

.productImageContainer {
  @include respond-to($lg) {
    margin: pxToRem(10px) 0;
    position: initial;
  }

  .productImage {
    position: relative;
    text-align: center;
    // set initial state for animations
    opacity: 0;
    margin: 0 auto pxToRem(40px) auto;
    height: 250px;
    @include respond-to($sm) {
      height: 300px;
    }
    @include respond-to($md) {
      height: 400px;
    }
    @include respond-to($lg) {
      height: 450px;
      margin-bottom: pxToRem(50px);
    }
    @include respond-to($xl) {
      height: 500px;
    }
    @include respond-to($xxl) {
      height: auto;
    }

    img {
      position: relative;
      z-index: 10;
      max-height: 100%;
      width: auto;
    }

    // background circle gradient
    @include bgRadialGradient(520px);
    @include respond-to($lg) {
      @include bgRadialGradient(650px);
    }
  }
}

%arrow {
  display: flex;
  align-items: center;
  width: 40px;
  height: 120px;
  position: absolute;
  z-index: 15;
  overflow: hidden;
  top: 210px;

  @include respond-to($sm) {
    top: 235px;
  }
  @include respond-to($md) {
    top: 285px;
  }

  & > span {
    display: inline-block;
    width: 100%;
    position: absolute;
    opacity: 0;
    height: 50px;
    left: 0;
    @include respond-to($lg) {
      height: 60px;
    }

    &::before,
    &::after {
      position: absolute;
      // Make sure that left and right are set for .leftArrow and .rightArrow
      margin: auto;
      content: '';
      z-index: 1;
      height: math.hypot(25px, 25px);
      width: 1px;
      background: $white;
      transition: background 0.15s ease;
      @include respond-to($lg) {
        height: math.hypot(30px, 30px);
      }
    }

    &::before {
      top: 0;
      transform-origin: top;
    }

    &::after {
      bottom: 0;
      transform-origin: bottom;
    }
  }

  &:hover span,
  &:focus span {
    &::before,
    &::after {
      background: var(--primary-theme-color);
    }
  }
}

.leftArrow {
  @extend %arrow;
  left: 20px;

  & > span {
    &::before,
    &::after {
      left: 50%;
      right: 0;
    }

    &::before {
      transform: rotate(45deg);
    }

    &::after {
      transform: rotate(-45deg);
    }
  }

  @include respond-to($sm) {
    left: 10px;
  }
  @include respond-to($md) {
    left: 0;
  }
}

.rightArrow {
  @extend %arrow;

  right: 20px;

  & > span {
    &::before,
    &::after {
      left: 0;
      right: 50%;
    }

    &::before {
      transform: rotate(-45deg);
    }

    &::after {
      transform: rotate(45deg);
    }
  }
  @include respond-to($sm) {
    right: 10px;
  }
  @include respond-to($md) {
    right: 0;
  }
}

.productImageIcons {
  width: 100%;
  margin: pxToRem(20px) 0;
  display: flex;
  justify-content: center;

  svg {
    margin-right: 10px;
    display: block;
    &:last-of-type {
      margin-right: 0;
    }
  }
}

.nutritionLabel {
  &[data-reach-dialog-content] {
    width: fit-content;
  }
}

// transition in on animateIn
.productContainer.animateIn {
  .productHeadingBlock {
    animation: revealItem forwards $animationDuration
      calc($animationDelay - $animationDelayAddend);
  }

  .productDetailsContainer > * {
    // support up to 5 elements for transition delays
    @for $i from 1 through 5 {
      &:nth-child(#{$i}) {
        animation: revealItem forwards $animationDuration;
        animation-delay: ($i * $animationDurationShort);
      }
    }
  }

  .productImage {
    animation: revealItem forwards $animationDuration
      calc($animationDelay - $animationDelayAddend);
  }

  .leftArrow span {
    animation: revealItem forwards $animationDuration $animationDelay;
  }

  .rightArrow span {
    animation: revealItem forwards $animationDuration $animationDelay;
  }
  .iconsContainer {
    animation: revealItem forwards $animationDuration $animationDelay;
  }
}
