@use '../../../styles/_partials' as *;
@use 'sass:math';

.outerSection {
  max-width: $containerMaxWidth;
  margin-left: auto;
  margin-right: auto;
}

.filterButtons {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: pxToRem(20px) pxToRem(40px) 0 pxToRem(40px);

  position: relative;
  &:after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;
    border-top: 1px solid $gray;
    transform: scale(0);
    animation: scaleToFull forwards 0.75s 0.5s;
  }
}

.filterButton {
  text-transform: uppercase;
  border: none;
  color: $white;
  background-color: transparent;
  font-family: $primaryFontRegular;
  cursor: pointer;
  padding: pxToRem(6px);
  padding-bottom: pxToRem(18px);
  transition: color 0.2s;

  &.selected,
  &:hover,
  &:focus,
  &:active {
    color: $primary;
  }
}

.filterButtonText {
  position: relative;
  @include font-size(16px);
  @include line-height(22);
  &::after {
    content: '';
    width: 100%;
    height: 1px;
    background-color: $primary;
    position: absolute;
    bottom: 0;
    left: 0;
    opacity: 0;
    transform: translateY(10px);
    transition: transform 0.4s, opacity 0.2s;
    transform-origin: right;
  }

  button.selected &,
  button:hover &,
  button:focus &,
  button:active & {
    &::after {
      opacity: 1;
      transition: transform 0.2s, opacity 0.1s;
      transform: scaleX(1);
      transform-origin: left;
    }
  }
}

.productsList {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  margin-top: pxToRem(20px);
  padding-bottom: pxToRem(40px);

  :global li.hidden {
    visibility: hidden;
    max-height: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }

  :global li.visible {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 0.3s ease var(--transition-delay),
      transform 0.3s ease var(--transition-delay);
  }

  &[data-animating='true'] :global li {
    opacity: 0;
    transform: translateX(-30px);
    transition: opacity 0.3s ease var(--transition-delay),
      transform 0.3s ease calc(var(--transition-delay) + 0.3s) !important;
  }
}

.listItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  padding: pxToRem(20px) 0;
  position: relative;
  opacity: 0;

  & a {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    color: transparent;
    z-index: 12;
    cursor: pointer;
  }

  @include respond-to($md) {
    width: 33.33%;
    padding: pxToRem(35px) 0;
  }
}
.bagImageWrapper {
  @function bgHalo($radius) {
    @return radial-gradient(
      circle $radius at center,
      rgba(255, 255, 255, 1) 0%,
      rgba(86, 86, 86, 0.25) 72%,
      rgba(21, 21, 21, 0) 100%
    );
  }

  position: relative;
  $imageWrapperWidth: 150px;
  $imageWrapperHeight: 212px;
  width: $imageWrapperWidth;
  height: $imageWrapperHeight;

  img {
    position: relative;
    z-index: 5;
    height: 100%;
    width: 100%;
    object-fit: contain;
    object-position: bottom;
  }

  &::before {
    pointer-events: none;
    content: '';
    position: absolute;
    opacity: 0;
    transition: opacity 0.35s;
    $gradientSize: 220px;
    width: $gradientSize;
    height: $gradientSize;
    left: math.div(($gradientSize - $imageWrapperWidth), -2);
    background-image: bgHalo(math.div($gradientSize, 2));
  }

  @include respond-to($lg) {
    $imageWrapperWidth: 220px;
    $imageWrapperHeight: 300px;
    width: $imageWrapperWidth;
    height: $imageWrapperHeight;

    &::before {
      $gradientSize: 420px;
      width: $gradientSize;
      height: $gradientSize;
      left: math.div(($gradientSize - $imageWrapperWidth), -2);
      top: math.div(($gradientSize - $imageWrapperHeight), -2);
      background-image: bgHalo(math.div($gradientSize, 2));
    }
  }

  li:hover &::before,
  li[data-is-active='true'] &::before {
    opacity: 0.5;
    transition: opacity 0.2s;

    @include respond-to($lg) {
      opacity: 0.3;
    }
  }
}

.sticker {
  position: absolute;
  z-index: 10;
  top: 0;
  right: 0;
  color: $black;
  background-color: $primary;
  font-family: $primaryFontBold;
  text-transform: uppercase;
  text-align: center;
  display: flex;
  align-items: center;
  @include font-size(10px);
  @include line-height(12);

  border-radius: 50%;
  width: 55px;
  height: 55px;

  @include respond-to($lg) {
    width: 78px;
    height: 78px;
    @include font-size(15px);
    @include line-height(18);
  }
}

.productTitle {
  text-align: center;
  margin-top: pxToRem(12px);
  padding: 0 pxToRem(8px) pxToRem(11px);
  font-family: $primaryFontBold;
  transition: color 0.2s;

  @include font-size(18px);
  @include line-height(25);

  @include respond-to($lg) {
    min-height: 83px;
    @include font-size(22px);
    @include line-height(26);
  }

  li:hover &,
  li[data-is-active='true'] & {
    color: $primary;
  }
}

.noShellsText {
  font-family: $primaryFontRegular;
}

.productDescription {
  @include font-size(14px);
  @include line-height(18);
  max-width: 120px;
  text-align: center;

  @include respond-to($lg) {
    @include font-size(16px);
    @include line-height(24);
    max-width: 220px;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .filterButton {
    opacity: 0;
    animation: revealItem forwards $animationDuration var(--transition-delay);
  }
}
