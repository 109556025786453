@use '@styles/_partials' as *;

.filterSection {
  max-width: $containerMaxWidth;
  margin: 0 auto;
}

.filterButtons {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 0 pxToRem(40px) 0 pxToRem(40px);
  position: relative;

  &:after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;
    border-top: 1px solid $gray;
    transform: scale(0);
    animation: scaleToFull forwards
      calc($animationDuration + $animationDurationAddend) $animationDelay;
  }
}

.filterButton {
  position: absolute;
  text-align: center;
  display: block;
  box-sizing: content-box;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  appearance: none;
  cursor: pointer;
  transition: color 0.2s;
}

.filterButtonText {
  position: relative;
  text-transform: uppercase;
  text-align: center;
  @include font-size(16px);
  @include line-height(22);
  margin: pxToRem(20px) pxToRem(10px) 0 pxToRem(10px);

  &::after {
    content: '';
    width: 100%;
    height: 1px;
    background-color: $primary;
    position: absolute;
    bottom: 0;
    left: 0;
    opacity: 0;
    transform: translateY(10px);
    transition: transform 0.4s, opacity 0.2s;
    transform-origin: right;
  }

  &.selected,
  &:hover,
  &:focus,
  &:active {
    color: $primary;
    &::after {
      opacity: 1;
      transition: transform 0.2s, opacity 0.1s;
      transform: scaleX(1);
      transform-origin: left;
    }
  }

  &.selected {
    font-family: $primaryFontBold;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .filterButtonText {
    opacity: 0;
  }

  .inViewport {
    .filterButtonText {
      animation: revealItem forwards $animationDuration
        calc($animationDelay + $animationDelayAddend);
    }
  }
}
