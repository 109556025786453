/** License for Arabic fonts
 * @license
 * MyFonts Webfont Build ID 3867246, 2020-12-16T11:57:38-0500
 * 
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are 
 * explicitly restricted from using the Licensed Webfonts(s).
 * 
 * You may obtain a valid license at the URLs below.
 * 
 * Webfont: HelveticaLTWXX-Roman by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/helvetica/pro-regular/
 * Copyright: Copyright © 2014 Monotype Imaging Inc. All rights reserved.
 * 
 * 
 * 
 * © 2020 MyFonts Incn*/

/* @import must be at top of file, otherwise CSS will not work */
// Custom implementation to record pageviews due to SPA nature of the site
// @import url('https://cdn.fonts.net/t/1.css?apiType=css&projectid=b230b330-23cc-11ed-aaf7-06b70d83f6b0');

@font-face {
  font-family: 'AvenirNextBold';
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
  src: url('../public/fonts/AvenirNextBold/AvenirNextBold_normal_normal.woff2')
      format('woff2'),
    url('../public/fonts/AvenirNextBold/AvenirNextBold_normal_normal.woff')
      format('woff');
}
@font-face {
  font-family: 'AvenirNextMedium';
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
  src: url('../public/fonts/AvenirNextMedium/AvenirNextMedium_normal_normal.woff2')
      format('woff2'),
    url('../public/fonts/AvenirNextMedium/AvenirNextMedium_normal_normal.woff')
      format('woff');
}
@font-face {
  font-family: 'AvenirNextRegular';
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
  src: url('../public/fonts/AvenirNextRegular/AvenirNextRegular_normal_normal.woff2')
      format('woff2'),
    url('../public/fonts/AvenirNextRegular/AvenirNextRegular_normal_normal.woff')
      format('woff');
}
@font-face {
  font-family: 'AvenirNextHeavy';
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
  src: url('../public/fonts/AvenirNextHeavy/AvenirNextHeavy_normal_normal.woff2')
      format('woff2'),
    url('../public/fonts/AvenirNextHeavy/AvenirNextHeavy_normal_normal.woff')
      format('woff');
}

// Arabic fonts
@font-face {
  font-family: 'AvenirNextArabicRegular';
  font-display: swap;
  src: url('../public/fonts/AvenirNextArabicRegular/font.woff2') format('woff2'),
    url('../public/fonts/AvenirNextArabicRegular/font.woff') format('woff');
}
@font-face {
  font-family: 'AvenirNextArabicHeavy';
  font-display: swap;
  src: url('../public/fonts/AvenirNextArabicHeavy/font.woff2') format('woff2'),
    url('../public/fonts/AvenirNextArabicHeavy/font.woff') format('woff');
}
@font-face {
  font-family: 'AvenirNextArabicMedium';
  font-display: swap;
  src: url('../public/fonts/AvenirNextArabicMedium/font.woff2') format('woff2'),
    url('../public/fonts/AvenirNextArabicMedium/font.woff') format('woff');
}
@font-face {
  font-family: 'AvenirNextArabicBold';
  font-display: swap;
  src: url('../public/fonts/AvenirNextArabicBold/font.woff2') format('woff2'),
    url('../public/fonts/AvenirNextArabicBold/font.woff') format('woff');
}
