@use '@styles/_partials' as *;
@use 'sass:map';
@import './lib/bootstrap/_functions';
@import './lib/bootstrap/_variables';

// this should be used to handle PDP colors
:root {
  --primary-theme-color: #{$primary};
}

html {
  --base: 16;
  font-size: calc((var(--base) / 16) * 100%);

  --primary-font-regular: 'AvenirNextRegular';
  --primary-font-bold: 'AvenirNextBold';
  --primary-font-heavy: 'AvenirNextHeavy';
  --primary-font-heavy-italic: 'AvenirNextHeavyItalic';
  --primary-font-medium: 'AvenirNextMedium';

  &:lang(ar) {
    --primary-font-regular: 'AvenirNextArabicRegular';
    --primary-font-bold: 'AvenirNextArabicBold';
    --primary-font-heavy: 'AvenirNextArabicHeavy';
    --primary-font-heavy-italic: 'AvenirNextArabicHeavy';
    --primary-font-medium: 'AvenirNextArabicMedium';
  }
}

body {
  @media screen and (max-device-width: 480px) {
    -webkit-text-size-adjust: none;
  }
}

html,
body {
  background-color: $offBlack;
  // overflow-x: hidden;
  // scroll-behavior: smooth;
}

a {
  font-family: inherit;
  color: inherit;
  font-size: inherit;
  line-height: inherit;
  text-decoration: none;

  p & {
    text-decoration: underline;
    transition: opacity 0.15s;

    &:hover,
    &:focus,
    &:active {
      transition: opacity 0.1s;
      opacity: 0.7;
    }
  }
}

body {
  font-family: $primaryFontRegular;
  color: $white;
  @include line-height(28);
  @include font-size(18px);
}

h1 {
  @include h1();
}

h2 {
  @include h2();
}

h3 {
  @include h3();
}

h4 {
  @include h4();
}

h5 {
  @include h5();
}

h6 {
  @include h6();
}

p,
strong,
ol,
ul,
li {
  font-family: $primaryFontRegular;
}

form,
button,
label,
input,
textarea,
select {
  font-family: $primaryFontRegular;
  @include font-size(16px);
  @include line-height(20);
  text-size-adjust: none;
}

pre {
  background: #f4f4f4;
  border: 1px solid #ddd;
  border-left: 3px solid #f36d33;
  color: #666;
  page-break-inside: avoid;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  @include font-size(15px);
  @include line-height(16);
  margin-bottom: 1.6em;
  max-width: 100%;
  overflow: auto;
  padding: 1em 1.5em;
  display: block;
  word-wrap: break-word;
}

strong {
  font-family: $primaryFontBold;
}

.bg-gradient__top {
  background-image: linear-gradient(180deg, $darkGray 0%, $offBlack 100%);
}

hr {
  border-color: $gray;
  margin: 30px 0;
  @include respond-to($lg) {
    margin: 40px 0;
  }
}

i,
em {
  font-style: italic;
}

blockquote {
  font-family: $primaryFontBold;
  position: relative;
  @include font-size(16px);
  @include line-height(24);
  margin: 15px 0;
  @include respond-to($md) {
    @include font-size(24px);
    @include line-height(28);
  }
  @include respond-to($lg) {
    @include font-size(30px);
    @include line-height(36);
  }

  &::before {
    position: absolute;
    top: 0;
    left: -15px;
    color: $white;
    content: '“';
    @include font-size(20px);
    @include respond-to($md) {
      @include font-size(30px);
    }
  }

  &::after {
    content: '\”';
    @include font-size(20px);
    @include respond-to($md) {
      @include font-size(30px);
    }
  }
}

// remove up/down arrows on number inputs
/* Chrome, Safari, Edge, Opera */
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

body {
  [data-reach-skip-link]:focus {
    background-color: transparent;
    color: var(--primary-theme-color);
  }
}
// remove storybook on iframe
.sb-show-main.sb-main-padded {
  padding: 0;
}

// custom styles for @reach/dialog
body {
  [data-reach-dialog-overlay] {
    background: rgba(0, 0, 0, 0.6);

    // center the content vertically
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    // cursor: pointer;
    opacity: 0;
    animation: fadeIn forwards $animationDurationShort;
    z-index: 9999;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: auto;

    [data-reach-dialog-content] {
      padding: 0;
      margin: 0;
      opacity: 0;
      animation: revealItem forwards calc($animationDurationShort * 2)
        $animationDurationShort;
      width: 100%;
      background: transparent;

      // WPA1-491 fix scrolling in modal
      max-height: 100vh;

      &:focus {
        outline: none;
      }
    }
  }
}

@supports (
  (-webkit-backdrop-filter: blur(10px)) or (backdrop-filter: blur(10px))
) {
  [data-reach-dialog-overlay] {
    background: transparent;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
  }
}

// WPA1-495 - handle weird overflow
#__next {
  overflow: hidden;

  main {
    min-height: 50vh;
  }
}

sub,
sup {
  /* Specified in % so that the sup/sup is the
     right size relative to the surrounding text */
  font-size: 75% !important;

  /* Zero out the line-height so that it doesn't
     interfere with the positioning that follows */
  line-height: 0;

  /* Where the magic happens: makes all browsers position
     the sup/sup properly, relative to the surrounding text */
  position: relative;

  /* Note that if you're using Eric Meyer's reset.css, this
     is already set and you can remove this rule */
  vertical-align: baseline;
}

sup {
  /* Move the superscripted text up */
  top: -0.5em;
  vertical-align: initial !important;
}

sub {
  /* Move the subscripted text down, but only
     half as far down as the superscript moved up */
  bottom: -0.25em;
}
