@use '@styles/_partials' as *;

.builderEditingArea {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: $ltBlue;
  text-align: center;
  color: $white;
  margin: 1rem 0 !important; // overwrite bootstrap
  padding: 1rem 0;

  .body {
    @include font-size(20px);
    @include line-height(22px);
    padding: 0 0.25rem;
    width: 100%;
  }

  .helper {
    font-style: italic;
    margin-top: 0.25rem;
    @include font-size(14px);
    @include line-height(16px);
  }
}
