@use '../../../styles/_partials' as *;

.container {
  width: 100%;
  max-width: 380px;
  position: relative;
  padding-top: pxToRem(50px);
  margin: auto;
  cursor: initial;

  // For mobile browsers blocking nfp close btn
  @include respond-to-max-height(920px) {
    margin: pxToRem(50px) auto auto auto;
  }
}

.wrapper {
  width: 300px;
  margin: auto;
  background-color: $white;
  color: $black;
  padding: pxToRem(5px);
}

.closeModal {
  position: absolute;
  height: 40px;
  width: 40px;
  top: 0;
  right: 0px;
  background: transparent;
  padding: 0;
  z-index: 1;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  #close {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid $white;
    color: $white;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    @include font-size(30px);
    @include line-height(30);

    span {
      background: white;
      height: 20px;
      position: relative;
      width: 2px;
      transform: rotate(45deg);
    }

    span:after {
      background: white;
      content: '';
      height: 2px;
      left: -9px;
      position: absolute;
      top: 9px;
      width: 20px;
    }
  }

  &:hover #close::after {
    content: '';
    position: absolute;
    overflow: visible;
    height: 40px;
    width: 40px;
    top: 0;
    left: 0;
    background-image: radial-gradient(
      circle at center center,
      rgba($white, 0.5) 0px,
      rgba($white, 0) 70%
    );
    z-index: -1;
  }
}
